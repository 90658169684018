import axios, { AxiosRequestConfig } from 'axios';
// import { from } from 'form-data';
import { urlRocketChat } from 'src/util/config';

import { v4 } from 'uuid';
const FormData = require('form-data');

const fetchApi = async (endPoint, method, params, data) => {
  let configs: AxiosRequestConfig = {
    url: `${urlRocketChat}/api/v1/${endPoint}`,
    method: method,
    headers: {
      'X-Auth-Token': localStorage.getItem('token'),
      'X-User-Id': localStorage.getItem('userId'),
      'Content-Type': 'application/json'
    },
    params,
    data: data
  };

  let response = await axios(configs);
  return response;
};

const fetchApiAppPoll = async (endPoint, method, params, data) => {
  let configs: AxiosRequestConfig = {
    url: `${urlRocketChat}/api/apps/${endPoint}`,
    method: method,
    headers: {
      'X-Auth-Token': localStorage.getItem('token'),
      'X-User-Id': JSON.parse(localStorage.getItem('dataUser') ?? '[]')?.find(
        el => el.token === localStorage.getItem('token')
      )?.id,
      'Content-Type': 'application/json'
    },
    params,
    data: data
  };

  let response = await axios(configs);
  return response;
};

export const sendMessageApi = async (
  // receiveId: any,
  _message: any,
  roomId: string,
  type?: string,
  typeForwards?: string,
  uuid?: string,
  mentions?: any,
  optionAutoMessage?: any
) => {
  const data = JSON.stringify({
    message: {
      rid: roomId ?? '38jF2HkKkLGEE4cJageEc922XhMPgFHqEg',
      msg: _message,
      type: type,
      typeForwards: typeForwards,
      isPage: type === 'chatPage' ? true : false,
      uuid: uuid,
      mentions: mentions,
      optionAutoMessage
    }
  });
  return fetchApi(`chat.sendMessage`, 'POST', null, data);
};

export const unSeenMessage = async (roomId: string) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '20',
      method: 'unreadMessages',
      params: [null, roomId]
    })
  });
  return fetchApi(`method.call/unreadMessages`, 'POST', null, data);
};

export const updateEmoticonConversation = async (
  roomId: string,
  icon: string,
  user: any
) => {
  const data = JSON.stringify({
    roomId: roomId,
    emoticon: {
      icon: icon
    },
    u: user
  });
  return fetchApi('rooms.setEmoticon', 'POST', null, data);
};

// get all topic from server

export const getAllTopics = async () => {
  return fetchApi('topic.get', 'GET', null, null);
};

export const updateTopicConversation = async (
  roomId: string,
  idTopic: string
) => {
  const data = JSON.stringify({
    roomId: roomId,
    topic: idTopic
  });
  return fetchApi('rooms.setTopic', 'POST', null, data);
};

export const changedRoleGroupConversation = async (
  roomId: string,
  t: string
) => {
  const data = JSON.stringify({
    roomId: roomId,
    t: t
  });
  return fetchApi('rooms.setType', 'POST', null, data);
};

export const getListMessage = async (roomId, perPage, lastMoment) => {
  var data = JSON.stringify({
    message: `{"msg":"method","id":"45","method":"loadHistory","params":["${roomId}",{"$date":${lastMoment}},${perPage}, null]}`
  });
  return fetchApi(`method.call/getMessages`, 'POST', null, data);
};

export const sendMessageUploadFile = async (
  receiveId?: string,
  file?: any,
  roomId?: string,
  time?: any,
  id?: any,
  type?: any
) => {
  let data = new FormData();
  data.append('file', file);
  data.append('uuid', id);
  if (type === 'chatPage') {
    data.append('isPage', 1);
  }
  data.append('description', 'file/image/video');

  if (time) {
    data.append('time', time);
  }
  if (receiveId) {
    data.append('msg', receiveId);
  }
  return fetchApi(`rooms.upload/${roomId}`, 'POST', null, data);
};

// not use
export const getListConversations = async () => {
  const data = JSON.stringify({
    message:
      '{"msg":"method","id":"11","method":"rooms/get","params":[{"$date":0}]}'
  });

  return fetchApi(`method.call/rooms%3Aget`, 'POST', null, data);
};

export const logoutCleanUp = async user => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '38',
      method: 'logoutCleanUp',
      params: [{ user }]
    })
  });
  return fetchApi(`method.callAnon/logoutCleanUp`, 'POST', null, data);
};

export const getDataMe = async () => {
  return fetchApi(`me`, 'GET', null, null);
};

// infinitiscroll
export const getSubscriptions = async (
  updatedAt: number | null,
  limit: number | null,
  lastUpdated: number | null
) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '14',
      method: 'subscriptions/get',
      params: [{ $date: updatedAt }, limit, { $date: lastUpdated }]
    })
  });

  return fetchApi(`method.call/subscriptions%3Aget`, 'POST', null, data);
};

// turn on popup vote

export const commandVote = async () => {
  const data = JSON.stringify({
    params: [
      {
        command: 'poll',
        eventName: 'slashCommandsStats',
        timestamp: Date.now()
      }
    ]
  });

  return fetchApi('statistics.telemetry', 'POST', null, data);
};

export const turnOnPopupVote = async (rid: string) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '56',
      method: 'slashCommand',
      params: [
        {
          cmd: 'poll',
          params: '',
          msg: {
            _id: v4(),
            rid,
            msg: '/poll'
          },
          triggerId: v4()
        }
      ]
    })
  });
  return fetchApi('method.call/slashCommand', 'POST', null, data);
};

export const getSubscriptionsPinned = async (lastUpdated: number) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '10',
      method: 'subscriptionsPinned/get',
      params: [{ $date: null }, { $date: lastUpdated }]
    })
  });

  return fetchApi(`method.call/subscriptionsPinned%3Aget`, 'POST', null, data);
};
// =======================================================================
// get all
export const getSubscriptionsAll = async (updatedAt, lastUpdated) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '17',
      method: 'subscriptions/get',
      params: [{ $date: updatedAt }, { $date: lastUpdated }]
    })
  });

  return fetchApi(`method.call/subscriptions%3Aget`, 'POST', null, data);
};

// Ignore members

export const IgnoreMembers = async (
  isIgnore: boolean,
  roomId: string,
  userId: any
) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '134',
      method: 'ignoreMembers',
      params: [
        {
          rid: roomId,
          uid: userId,
          ignore: isIgnore
        }
      ]
    })
  });
  return fetchApi(`method.call/ignoreUser`, 'POST', null, data);
};
// find or create new invites link
export const findOrCreateInviteLink = async ({
  days = 7,
  maxUses = 0,
  rid
}) => {
  const data = JSON.stringify({
    days,
    maxUses,
    rid
  });
  return fetchApi(`findOrCreateInvite`, 'POST', null, data);
};
// set active status user

export const setActiveStatusUser = async (status: string) => {
  const data = JSON.stringify({
    message: '',
    status
  });
  return fetchApi(`users.setStatus`, 'POST', null, data);
};

// validate Token
export const validateInviteToken = async (token: string) => {
  const data = JSON.stringify({
    token
  });
  return fetchApi(`validateInviteToken`, 'POST', null, data);
};
// use token get room
export const handleUseInvitesToken = async (token: string) => {
  const data = JSON.stringify({
    token
  });
  return fetchApi(`useInviteToken`, 'POST', null, data);
};
// show invite room
export const showInviteRoom = async (token: string) => {
  const data = JSON.stringify({
    token
  });
  return fetchApi(`isShowInviteRoom`, 'POST', null, data);
};

export const getListUsers = async (ids: string) => {
  const params = {
    ids: ids
  };
  return fetchApi(`users.presence`, 'GET', params, null);
};

export const getUser = async (userId: string) => {
  const params = {
    userId
  };
  return fetchApi(`users.info`, 'GET', params, null);
};

export const getListFiles = async (
  roomId: string,
  count: number,
  offset: number,
  type?: string
) => {
  const params: {
    roomId: string;
    count: number;
    offset: number;
    sort: string;
    query: string;
  } = {
    roomId,
    count,
    offset,
    sort: JSON.stringify({ uploadedAt: -1 }),
    query: JSON.stringify({
      $or: [{ typeGroup: 'application' }, { typeGroup: 'text' }]
    })
  };

  if (type) {
    const typeGroup = type === 'file' ? ['text', 'application'] : [type];
    params.query = JSON.stringify({ typeGroup: { $in: typeGroup } });
  }

  return fetchApi(`im.files`, 'GET', params, null);
};

export const getFileMediaLink = async (
  roomId: any,
  offset: number,
  count: number,
  typeGroup: any
) => {
  const params = {
    roomId: roomId,
    offset: offset,
    count: count,
    sort: JSON.stringify({ uploadedAt: -1 }),
    query: JSON.stringify({
      name: { $regex: '', $options: 'i' },
      typeGroup: typeGroup
    })
  };
  return fetchApi(`im.files`, 'GET', params, null);
};

export const getListFilesGroup = async (
  roomId: string,
  count: number,
  offset: number,
  type: string
) => {
  const params: {
    roomId: string;
    count: number;
    offset: number;
    sort: { uploadedAt: number };
    query: {
      typeGroup?: string | { $in: string[] };
    };
  } = {
    roomId,
    count,
    offset,
    sort: { uploadedAt: -1 },
    query: {}
  };
  if (type === 'file') {
    params.query.typeGroup = { $in: ['text', 'application'] };
  } else if (type === 'link') {
    params.query.typeGroup = { $in: ['link'] };
  } else {
    params.query.typeGroup = type;
  }

  return fetchApi(`groups.files`, 'GET', params, null);
};

export const getFileMediaGroupLink = async (
  roomId: any,
  offset: number,
  count: number,
  typeGroup: any
) => {
  const params = {
    roomId: roomId,
    offset: offset,
    count: count,
    sort: JSON.stringify({ uploadedAt: -1 }),
    query: JSON.stringify({
      name: { $regex: '', $options: 'i' },
      typeGroup: typeGroup
    })
  };
  return fetchApi(`groups.files`, 'GET', params, null);
};

export const createGroupChat = async (
  roomName: string,
  listMember: Object[],
  isSocialChat = ''
) => {
  let data = JSON.stringify({
    name: roomName,
    members: listMember,
    extraData: {
      description: isSocialChat,
      broadcast: false,
      encrypted: false
    },
    readOnly: false
  });
  return fetchApi(`groups.create`, 'POST', null, data);
};

export const handleCreateSocialChat = async (
  roomName: string,
  listMember: Object[]
) => {
  let data = JSON.stringify({
    name: roomName,
    members: listMember,
    type: 'socialChat',
    extraData: {
      description: 'socialChat',
      broadcast: false,
      encrypted: false
    },
    readOnly: false
  });
  return fetchApi(`channels.create`, 'POST', null, data);
};

export const createLinkInviteGroup = async (roomId: string, days: number) => {
  let data = JSON.stringify({
    days: days,
    maxUses: 0,
    rid: roomId
  });
  return fetchApi(`findOrCreateInvite`, 'POST', null, data);
};

export const createDirectMessage = async (username: string) => {
  var data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '83',
      method: 'createDirectMessage',
      params: [username]
    })
  });
  return fetchApi(`method.call/getRoomRoles`, 'POST', null, data);
};

export const getInfoRoom = async (roomId: string) => {
  const params = {
    roomId
  };
  return fetchApi(`rooms.info`, 'GET', params, null);
};

export const getTeamInfoById = async (teamId: string) => {
  const params = {
    teamId
  };

  return fetchApi(`teams.info`, 'GET', params, null);
};

export const searchConversation = async key => {
  const params = {
    selector: {
      term: key
    }
  };
  return fetchApi(`users.autocomplete`, 'GET', params, null);
};

export const searchConversationGroup = async (key: string) => {
  const params = {
    name: key
  };
  return fetchApi(`channels.list`, 'GET', params, null);
};

export const searchMemberChannelGroupChat = async (
  count: number,
  filter: string,
  offset: number,
  roomId: string
) => {
  const params = {
    count,
    filter,
    offset,
    roomId
  };
  return fetchApi('channels.members', 'GET', params, null);
};

export const searchInConversation = async (keyword: string, rid: string) => {
  const params = {
    roomId: rid,
    searchText: keyword
  };
  return fetchApi(`chat.search`, 'GET', params, null);
};

export const loadSurroundingMessage = async (message: any) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '37',
      method: 'loadSurroundingMessages',
      params: [
        {
          customClass: 'search',
          actionContext: 'search',
          ...message,
          searchedText: message.msg,
          groupable: false,
          html: message.msg,
          tokens: []
        },
        20
      ]
    })
  });
  return fetchApi(`method.call/loadSurroundingMessages`, 'POST', null, data);
};

export const loadSurroundingMessageReply = async (message: any) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '37',
      method: 'loadSurroundingMessages',
      params: [
        {
          customClass: 'search',
          actionContext: 'search',
          searchedText: message.text,
          ...message,
          groupable: false,
          html: message.text,
          tokens: []
        },
        20
      ]
    })
  });
  return fetchApi(`method.call/loadSurroundingMessages`, 'POST', null, data);
};

export const seenMessage = async (roomId: string) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '65',
      method: 'readMessages',
      params: [roomId]
    })
  });
  return fetchApi(`method.call/readMessages`, 'POST', null, data);
};

// setting: noti , ...
export const saveSettingRoom = async (roomId: string, settings: object) => {
  const data = JSON.stringify({
    roomId,
    notifications: settings
  });
  return fetchApi(`rooms.saveNotification`, 'POST', null, data);
};

export const getSubscriptionRoom = async roomId => {
  const params = {
    roomId
  };
  return fetchApi(`subscriptions.getOne`, 'GET', params, null);
};

export const blockRoom = async (roomId: string, userId: string) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '65',
      method: 'blockUser',
      params: [
        {
          rid: roomId,
          blocked: userId
        }
      ]
    })
  });
  return fetchApi(`method.call/blockUser`, 'POST', null, data);
};
export const blockPage = async (roomId: string, checkBlock: boolean) => {
  const data = JSON.stringify({
    roomId,
    checkBlock
  });
  return fetchApi(`rooms.blockPage`, 'POST', null, data);
};
export const unBlockPage = async (roomId: string, checkBlock: boolean) => {
  const data = JSON.stringify({
    roomId,
    checkBlock
  });
  return fetchApi(`rooms.unBlockPage`, 'POST', null, data);
};

export const unBlockRoom = async (roomId: string, userId: string) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '65',
      method: 'unblockUser',
      params: [
        {
          rid: roomId,
          blocked: userId
        }
      ]
    })
  });
  return fetchApi(`method.call/unblockUser`, 'POST', null, data);
};

// update name, avatar group chat
export const saveInfoGroupChat = async (roomId: string, form: any) => {
  const data = {
    rid: roomId,
    roomName: form.roomName,
    roomAvatar: form.roomAvatar
  };
  return fetchApi(`rooms.saveRoomSettings`, 'POST', null, data);
};

export const handleToggleRoleChatGroup = async (
  roomId: string,
  roleChat: boolean
) => {
  const data = {
    rid: roomId,
    readOnly: roleChat
  };

  return fetchApi(`rooms.saveRoomSettings`, 'POST', null, data);
};

export const outGroupChat = roomId => {
  const data = JSON.stringify({
    roomId
  });
  return fetchApi('groups.leave', 'POST', null, data);
};

export const outChannelChat = roomId => {
  const data = JSON.stringify({
    roomId
  });
  return fetchApi('channels.leave', 'POST', null, data);
};

// api group chat
// get list admin, moderator, owner, leader group chat
export const getRoomRoles = async roomId => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '14',
      method: 'getRoomRoles',
      params: [roomId]
    })
  });

  return fetchApi(`method.call/getRoomRoles`, 'POST', null, data);
};

export const removeMemberGroupChat = async (roomId: string, userId: string) => {
  const data = {
    roomId,
    userId
  };

  return fetchApi(`groups.kick`, 'POST', null, data);
};

export const addMemberAdminGroupChat = async (
  roomId: string,
  userId: string
) => {
  const data = {
    roomId,
    userId
  };

  return fetchApi(`groups.addOwner`, 'POST', null, data);
};

export const removeMemberAdminGroupChat = async (
  roomId: string,
  userId: string
) => {
  const data = {
    roomId,
    userId
  };

  return fetchApi(`groups.removeOwner`, 'POST', null, data);
};

export const addMemberGroupChat = async (roomId: any, listUser: any[]) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '66',
      method: 'addUsersToRoom',
      params: [
        {
          rid: roomId,
          users: listUser
        }
      ]
    })
  });
  return fetchApi(`method.call/addUsersToRoom`, 'POST', null, data);
};

export const pinMessage = async (messageId: any, roomId: any) => {
  const data = JSON.stringify({
    messageId,
    roomId
  });
  return fetchApi(`chat.pinMessage`, 'POST', null, data);
};

export const unPinMessage = async (messageId: any, roomId: any) => {
  const data = JSON.stringify({
    messageId,
    roomId
  });
  return fetchApi(`chat.unPinMessage`, 'POST', null, data);
};

export const deleteMessage = async (messageId: string) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '56',
      method: 'deleteMessage',
      params: [
        {
          _id: messageId
        }
      ]
    })
  });
  return fetchApi(`method.call/deleteMessage`, 'POST', null, data);
};

export const favoriteConversation = async (
  roomId: string,
  favorite: boolean
) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '22',
      method: 'toggleFavorite',
      params: [roomId, favorite]
    })
  });
  return fetchApi(`method.call/toggleFavorite`, 'POST', null, data);
};

export const updateMessage = async (
  messageId: string,
  roomId: string,
  contentMessage: string
) => {
  const data = JSON.stringify({
    roomId: roomId,
    msgId: messageId,
    text: contentMessage
  });

  return fetchApi(`chat.update`, 'POST', null, data);
};

export const hideConversation = async (roomId: string) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '31',
      method: 'hideRoom',
      params: [roomId]
    })
  });
  return fetchApi(`method.call/hideRoom`, 'POST', null, data);
};

// hide Conversation

export const hideUserConversation = async (roomId: string) => {
  const data = JSON.stringify({
    roomId
  });
  return fetchApi('im.close', 'POST', null, data);
};

export const hideGroupConversation = async (roomId: string) => {
  const data = JSON.stringify({
    roomId
  });
  return fetchApi('groups.close', 'POST', null, data);
};

export const hideChannelConversation = async (roomId: string) => {
  const data = JSON.stringify({
    roomId
  });
  return fetchApi('channels.close', 'POST', null, data);
};

export const getInfoMeChat = async () => {
  return fetchApi(`me`, 'GET', null, null);
};

export const getPinMessage = async (count: number, roomId: string) => {
  const params = {
    count: count,
    roomId: roomId
  };
  return fetchApi(`chat.getPinnedMessages`, 'GET', params, null);
};

export const setReactMessage = async (messageId: string, react: string) => {
  const data = JSON.stringify({
    message: JSON.stringify({
      msg: 'method',
      id: '20',
      method: 'setReaction',
      params: [react, messageId]
    })
  });
  return fetchApi(`method.call/setReaction`, 'POST', null, data);
};

export const getListGroupChat = async (count: number, lastUpdated: string) => {
  const params = {
    count,
    sort: { _updatedAt: -1 }
  };
  return fetchApi(`channels.list.joined`, 'GET', params, null);
};

export const getListSocialChat = async (count: number) => {
  const params = {
    count,
    sort: { _updatedAt: -1 }
  };
  return fetchApi(`channels.socialChat`, 'GET', params, null);
};

/**
 *
 * @param {Number} limit
 * @param {Number} offset
 * @returns {Array<Object>} results
 */

export const getListUserBlockedMessages = async (
  limit: number,
  offset: number
) => {
  const params = {
    limit,
    offset
  };
  return fetchApi(`subscriptions.blockedUserChat`, 'GET', params, null);
};

export const getListMemberGroupChat = async (
  count: number,
  offset: number,
  roomId: string,
  filter: string | null = null
) => {
  const params = {
    limit: count,
    offset,
    rid: roomId,
    query: filter
  };
  return fetchApi(`groups.getMembersGroup`, 'GET', params, null);
};
// export const getListMemberGroupChat = async (
//   count: number,
//   offset: number,
//   roomId: string,
//   filter: string | null = null
// ) => {
//   const params = {
//     count,
//     offset,
//     roomId,
//     filter
//   };
//   return fetchApi(`groups.members`, 'GET', params, null);
// };

export const getMembersOfSocialConversation = async (
  count: number,
  offset: number,
  roomId: string,
  filter: string | null = null
) => {
  const params = {
    count,
    offset,
    roomId,
    filter
  };
  return fetchApi(`channels.members`, 'GET', params, null);
};

export const getMessageReadReceipts = async (messageId: string) => {
  const params = {
    messageId
  };
  return fetchApi(`chat.getMessageReadReceipts`, 'GET', params, null);
};

export const handleCleanHistory = async (params: any) => {
  return fetchApi(`rooms.cleanHistory`, 'POST', null, params);
};

export const getListGroupsChat = async (params: any) => {
  return fetchApi(`groups.listAll?limit=${params}`, 'GET', null, null);
};
export const createRoomChatPage = async (params: any) => {
  return fetchApi(`rooms.createChatPage`, 'POST', null, params);
};
export const getListConversationsChatPage = async (params: any) => {
  return fetchApi(`rooms.getDiscussions`, 'GET', params, null);
};

export const getFilterListConversationChatPage = async (
  roomId: string,
  label: Array<any>
) => {
  const params = {
    roomId,
    label: JSON.stringify(label)
  };
  return fetchApi(`rooms.getDiscussions`, 'GET', params, null);
};

// app poll
export const handleActionVote = async (
  message: any,
  voteIndex: any,
  actionId: string
) => {
  const data = JSON.stringify({
    actionId: actionId,
    container: {
      type: 'message',
      id: message._id
    },
    mid: message._id,
    payload: {
      blockId: message.blocks[0].blockId,
      value: voteIndex
    },
    rid: message.rid,
    triggerId: v4(),
    type: 'blockAction'
  });

  return fetchApiAppPoll(
    `ui.interaction/c33fa1a6-68a7-491e-bf49-9d7b99671c48`,
    'POST',
    null,
    data
  );
};
/**
 *
 * @param {Object} config
 * @param  {Object} poll
 * @returns {Boolean}
 */

export const submitCreateVote = async (config: any, poll: any, viewId: any) => {
  const data = JSON.stringify({
    payload: {
      view: {
        appId: 'c33fa1a6-68a7-491e-bf49-9d7b99671c48',
        id: viewId,
        showIcon: true,
        type: 'modal',
        state: {
          config,
          poll
        }
      }
    },
    viewId: viewId,
    type: 'viewSubmit',
    triggerId: v4()
  });
  return fetchApiAppPoll(
    `ui.interaction/c33fa1a6-68a7-491e-bf49-9d7b99671c48`,
    'POST',
    null,
    data
  );
};

/**
 * @param {String} teamId
 * @param {Array} automatic_reply
 * @returns {Object} team information
 */

export const createOptionsAutomaticReply = async (
  teamId: string,
  automatic_reply: any
) => {
  const data = JSON.stringify({
    teamId,
    automatic_reply
  });
  return fetchApi('teams.automaticReply', 'POST', null, data);
};

/**
 *
 * @param {String} roomId
 * @param {Object} label
 * @returns
 */

export const addLabelIntoChatPage = async (roomId: string, label: Object) => {
  const data = JSON.stringify({
    roomId,
    label
  });

  return fetchApi('rooms.addLabel', 'POST', null, data);
};
/**
 *
 * @param roomId
 * @param label
 * @returns
 */
export const removeLabelChatPage = async (roomId: string, label: Object) => {
  const data = JSON.stringify({
    roomId,
    label
  });
  return fetchApi('rooms.removeLabel', 'POST', null, data);
};
/**
 *
 * @param {String} teamId
 * @param {Object} label
 * @returns
 */

export const removeLabelPage = async (teamId: string, label: Object) => {
  const data = JSON.stringify({
    teamId,
    label
  });

  return fetchApi('teams.removeLabelPage', 'POST', null, data);
};
/**
 *
 * @param {String} teamId
 * @param {Object} label
 * @returns
 */
export const addLabelPage = async (teamId: string, label: Object) => {
  const data = JSON.stringify({
    teamId,
    label
  });

  return fetchApi('teams.addLabelChatPage', 'POST', null, data);
};

export const searchConversationChatPage = async (
  keyword: string,
  prid: string
) => {
  const params = {
    selector: { term: keyword },
    prid
  };
  return fetchApi('users.getDiscussionChatpage', 'GET', params, null);
};

interface Note {
  id: string;
  note: string;
  time: number;
}

export const setNoteChatPage = async (roomId: string, note: Note) => {
  const data = JSON.stringify({
    roomId,
    note
  });
  return fetchApi('rooms.setNote', 'POST', null, data);
};

export const removeNoteChatPage = async (roomId: string, note: Note) => {
  const data = JSON.stringify({
    roomId,
    note
  });
  return fetchApi('rooms.deleteNote', 'POST', null, data);
};

export const updateNoteChatPage = async (roomId: string, note: Note) => {
  const data = JSON.stringify({
    roomId,
    note
  });
  return fetchApi('rooms.updateNote', 'POST', null, data);
};
export const getListNewPendingMessages = async (data: any) => {
  return fetchApi('getListNewPendingMessages', 'POST', null, data);
};
export const getCountMessagePage = async (data: any) => {
  const pageId = JSON.stringify({
    teamsIds: data
  });
  return fetchApi('channels.countUnreadPages', 'POST', null, pageId);
};
export const seenMessagePage = async (roomId: any) => {
  const data = JSON.stringify({
    rid: roomId
  });
  return fetchApi('channels.readPages', 'POST', null, data);
};
export const unseenMessagePage = async (roomId: any) => {
  const data = JSON.stringify({
    roomId: roomId
  });
  return fetchApi('rooms.markUnreadPage', 'POST', null, data);
};
