import * as React from 'react';
import {
  Dialog,
  DialogContent,
  Divider,
  Typography,
  DialogTitle,
  DialogActions,
  Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import noti from 'src/assets/images/Noti.svg';

import IconButtonOptions from 'src/components/Button/IconButtonOption';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { handleBlockRoom } from 'src/pages/Chat/functionChat';
import { useSelector } from 'react-redux';
import {
  blockerPage,
  blockerUser,
  unBlockerUser
} from 'src/store/action/socialChatAction';
import { useDispatch } from 'react-redux';
import { buttonColor } from 'src/constants/styles';
import SocialCutImage from 'src/components/SocialCutImage/SocialCutImage';
import { isSafari } from 'react-device-detect';

function DialogBlockMember(props) {
  const { open, setOpen, conversation, userSelected, setIsToastMessage } =
    props;
  const theme: any = useTheme();

  const dispatch = useDispatch();
  const rocketId = useSelector((state: any) => state.meReducer.info).id || '';
  const handleBlockUser = async () => {
    await handleBlockRoom(
      conversation,
      conversation.type === 'cp'
        ? { userId: conversation.user_chat_page?._id }
        : userSelected,
      setIsToastMessage,
      dispatch,
      rocketId
    );
    if (rocketId !== conversation.u?._id) {
      dispatch(blockerPage(conversation._id));
    }
    if (conversation.blocker === true) {
      dispatch(unBlockerUser(conversation._id));
    } else {
      dispatch(blockerUser(conversation._id));
    }

    await handleClose();
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: '8px',
            maxHeight: '300px',
            width: '500px',
            zIndex: 0
          }
        }}
      >
        <DialogTitle
          style={{
            display: 'flex',
            width: '100%',
            height: '40px',
            padding: '8px'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 'calc(100% - 36px)',
              height: '100%'
            }}
          >
            <Typography style={{ fontSize: '20px', fontWeight: '700' }}>
              {conversation.blocker === true ? 'Bỏ chặn' : 'Chặn'}{' '}
              {conversation.t === 'cp' || conversation.type === 'chat_page'
                ? conversation?.real_name
                : conversation.type === 'user' || conversation.t === 'd'
                ? conversation.fname
                : userSelected?.name}
            </Typography>
          </div>
          <div
            style={{
              height: '100%',
              width: '36px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <IconButtonOptions
              icon={
                <i
                  style={{ fontSize: 14, fontWeight: 600, color: '#505256' }}
                  className="fa-light fa-xmark-large"
                ></i>
              }
              action={() => handleClose()}
              style={{
                borderRadius: '50%',
                width: '36px',
                marginRight: '0',
                zIndex: 1,
                top: '12px',
                right: '16px',
                position: 'absolute'
              }}
            />
          </div>
        </DialogTitle>
        <Divider sx={{ marginTop: '5px' }} />
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '12px 0px 12px 0px'
          }}
        >
          {(conversation.t === 'cp' || conversation.type === 'chat_page') &&
          !conversation?.user_chat_page ? (
            <Box sx={{ display: 'flex', width: '100%' }}>
              <Box sx={{ width: '80px', height: 'auto' }}>
                <Box
                  sx={{
                    backgroundColor: 'button.secondary.background',
                    width: '50px',
                    height: '50px',
                    borderRadius: '100%',
                    marginLeft: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <SocialCutImage
                    image={noti}
                    item={{
                      positionX: 0,
                      positionY:
                        theme.palette.mode === 'dark'
                          ? isSafari
                            ? -655
                            : -663
                          : isSafari
                          ? -88
                          : -83
                    }}
                    zoom={0.6}
                    style={{ height: '35px', width: '36px' }}
                  />
                </Box>
              </Box>
              <Box sx={{ marginLeft: '10px', width: 'calc(100% - 80px)' }}>
                <Typography variant="h6">Chặn tin nhắn</Typography>
                <li>Trang sẽ không nhận được tin nhắn từ người dùng này.</li>
              </Box>
            </Box>
          ) : (
            <Typography style={{ fontSize: '14px', padding: '12px 0 0' }}>
              Bạn có muốn {conversation.blocker === true ? 'bỏ' : ''} chặn{' '}
              {conversation.t === 'cp' || conversation.type === 'chat_page'
                ? conversation?.real_name
                : conversation.type === 'user' || conversation.t === 'd'
                ? conversation?.fname
                : userSelected?.name ?? userSelected?.displayName}
              ?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            label={'Hủy'}
            style={{
              width: '40px',
              height: '30px',
              // color: buttonColor.backgroundColor,
              // backgroundColor: '#e4e6eb',
              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleClose}
          />
          <ButtonInherit
            label={'Xác nhận'}
            style={{
              width: '100px',
              height: '30px',
              color: buttonColor.backgroundColor,
              backgroundColor: theme.palette.background.secondary,

              fontSize: '12px',
              fontWeight: '500'
            }}
            action={handleBlockUser}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogBlockMember;
