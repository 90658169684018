/* eslint-disable array-callback-return */ /* eslint-disable prettier/prettier */ import {
  Box,
  Button,
  Divider,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listMenuPost } from 'src/constants/common';
import { boxShadow } from 'src/constants/styles';

import useMediaQuery from '@mui/material/useMediaQuery';
import _ from 'lodash';
import { useHistory, useRouteMatch } from 'react-router-dom';
import DialogCUMoment from 'src/components/Dialog/DialogCUMoment';
import DialogCUPost from 'src/components/Dialog/DialogCUPost';
import DialogLocation from 'src/components/Dialog/DialogLocation';
import DialogShowActivitiesAction from 'src/components/Dialog/DialogShowActivitiesAction';
import DialogTagFriend from 'src/components/Dialog/DialogTagFriend';
import AvatarSocial from 'src/components/ProfileCardImage/AvatarSocial';
import { PAGE_TYPE } from 'src/constants/pageType';
import { PATHS } from 'src/constants/paths';
import { openDialogPost } from 'src/store/action/socialLiveStreamAction';
import {
  createPostRequestAction,
  notiSharePost,
  resetErrorPost,
  resetPostLoad,
  updateIsLoad,
  updatePostRequestAction
} from 'src/store/action/socialPostAction';
import CustomPrompt from '../CustomPrompt';
import NotiPost from '../Snackbar/NotiPost';
import { RolePreviewEdit } from 'src/store/reducer/socialPageReducer';

const useStyles = makeStyles((theme: Theme) => ({
  message_sender_top: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px'
  },
  message_sender: {
    marginTop: '20px',
    width: '100%',
    borderRadius: '10px',
    padding: '12px 16px',
    ...boxShadow
  },
  buttonCreatePost: {
    width: '92%',
    borderRadius: 20,
    // backgroundColor: 'background.secondary',
    // '&:hover': {
    //   cursor: 'pointer',
    //   backgroundColor: '#DADDE1'
    // },
    padding: 10,
    marginLeft: 10,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden'
  },
  message_sender_bottom: {
    width: '100%',
    display: 'flex',
    alignItemsL: 'center',
    justifyContent: 'space-around',
    padding: 8,
    paddingBottom: 0
  },
  wrapButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

interface CUPost {
  type?: String | any;
  post?: any;
  openCUPost?: boolean;
  setOpenCUPost?: React.Dispatch<React.SetStateAction<boolean>> | any;
  pageInfo?: any;
  eventInfo?: any;
  projectInfo?: any;
  recruitInfo?: any;
  courseInfo?: any;
  course?: any;
  group?: any;
  page?: any;
  event?: any;
  project?: any;
  recruit?: any;
  product?: any;
  groupShared?: any;
  pageShared?: any;
  handleFinal?: any;
  typePost?: String | any;
  uploadVideo?: any;
  friendShared?: any;
  newFiles?: any;
  newLifeEvent?: any;
  shareNow?: any;
  share?: any;
  errorPost?: any;
  styleLiveStream?: React.CSSProperties | any;
  listMenuLiveStream?: any;
  textContentLive?: any;
  setTextContentLive?: React.Dispatch<React.SetStateAction<any>> | any;
  idPostNoti?: String | any;
  setIdPostNoti?: React.Dispatch<React.SetStateAction<any>> | any;
  updateTags?: any;
  setUpdateTags?: React.Dispatch<React.SetStateAction<any>> | any;
  shareTo?: any;
  setSharePostNow?: React.Dispatch<React.SetStateAction<any>> | any;
  sharePostInNewFeed?: React.Dispatch<React.SetStateAction<any>> | any;
  setSharePostInNewFeed?: React.Dispatch<React.SetStateAction<any>> | any;
  setOpenAl?: React.Dispatch<React.SetStateAction<boolean>> | any;
  music?: any;
  placeCreatePost?: any;
  allowDiscussion?: any;
  increment_reblog_count?: any;
  isSharePost?: boolean;
}

const CUPost: React.FC<CUPost> = props => {
  const {
    type,
    post,
    openCUPost,
    setOpenCUPost,
    pageInfo,
    eventInfo,
    projectInfo,
    recruitInfo,
    courseInfo,
    course,
    group,
    page,
    event,
    project,
    recruit,
    product,
    groupShared,
    pageShared,
    handleFinal,
    typePost,
    uploadVideo,
    friendShared,
    newFiles,
    newLifeEvent,
    shareNow,
    share,
    errorPost,
    styleLiveStream,
    listMenuLiveStream,
    textContentLive,
    setTextContentLive,
    idPostNoti,
    setIdPostNoti,
    updateTags,
    setUpdateTags,
    shareTo,
    setSharePostNow,
    sharePostInNewFeed,
    setSharePostInNewFeed,
    setOpenAl,
    music,
    placeCreatePost,
    allowDiscussion,
    increment_reblog_count,
    isSharePost
  } = props;
  const history = useHistory();
  const match = useRouteMatch();
  const classes = useStyles();
  const meInfo = useSelector((state: any) => state?.meReducer?.info) || {};
  const isLoad =
    useSelector((state: any) => state.socialPostReducer.isLoad) || false;
  const groupSelected =
    useSelector((state: any) => state.groupReducer.groupSelected) || {};
  const rolePage: RolePreviewEdit = useSelector(
    (state: any) => state.pageReducer?.rolePreviewEdit
  );
  const [openNoti, setOpenNoti] = React.useState(false);
  const dispatch = useDispatch();
  const matches = useMediaQuery('(min-width:600px)');
  const [textContent, setTextContent] = React.useState<any>('');
  const [activitiesSelected, setActivitiesSelected] = React.useState<any>(null);
  const [parentSelected, setParentSelected] = React.useState<any>(null);
  const [menuSelected, setMenuSelected] = React.useState<any>([]);
  const [openUploadMedia, setOpenUploadMedia] = React.useState(false);
  const [openDialogAE, setOpenDialogAE] = React.useState(false);
  const [openDialogMoment, setOpenDialogMoment] = React.useState<any>(false);
  const [objectMentions, setObjectMentions] = React.useState<any>([]);
  const [openTagFriend, setOpenTagFriend] = React.useState(false);
  const [openLocation, setOpenLocation] = React.useState(false);
  const [friendSelected, setFriendSelected] = React.useState<any>([]);
  const [locationSelected, setLocationSelected] = React.useState<any>(null);
  const [schedulePost, setSchedulePost] = React.useState<any>(null);
  const [notificationLeave, setNotificationLeave] =
    React.useState<boolean>(false);
  const pageInteract = pageInfo ?? eventInfo?.page ?? projectInfo?.page_owner;

  useEffect(() => {
    if (uploadVideo) {
      setOpenUploadMedia(true);
    }
  }, [uploadVideo]);
  const listMenuRender = !matches
    ? listMenuPost
        ?.filter((el: any) => el.priority && el.priority === 'space')
        .slice(0, 2)
    : listMenuPost?.filter((el: any) => el.priority && el.priority === 'space');

  const handleAction = key => {
    setMenuSelected(prev => (prev.includes(key) ? prev : [...prev, key]));
    if (key === 'media') {
      setOpenCUPost(true);
      setOpenUploadMedia(true);
    } else if (key === 'emoji-activity') {
      setOpenDialogAE(true);
    } else if (key === 'moment') {
      setOpenDialogMoment(true);
    } else if (key === 'tag-people') {
      return setOpenTagFriend(true);
    } else if (key === 'checkin') {
      return setOpenLocation(true);
    }
  };

  const handlePostShareNow = () => {
    let data: any = {
      visibility: 'public',
      extra_body: {},
      life_event: null,
      poll: null,
      mention_ids: null,
      reblog_of_id:
        post && (type !== 'update' || type !== 'update_product')
          ? post.reblog
            ? post.reblog.id
            : post.id
          : null,
      post_type: null,
      status: '',
      shared_group_id: group?.id,
      shared_page_id: page?.id,
      shared_event_id: event?.id,
      shared_project_id: project?.id,
      shared_music_id: music?.id,
      shared_recruit_id: recruit?.id,
      shared_course_id: course?.id,
      target_account_id: friendShared?.id
    };
    dispatch(resetErrorPost());
    handleActionCreatePost(data, null, setOpenUploadMedia);
    setSharePostNow && setSharePostNow(false);
  };

  const renderButton = (data, index) => {
    return (
      <Button
        onClick={() => handleAction(data.key)}
        key={data.key ?? index}
        sx={{ textTransform: 'none' }}
        color="inherit"
      >
        <div className={classes.wrapButton}>
          {data.icon ? (
            <i
              style={{ color: data.color, fontSize: 23, marginRight: '3px' }}
              className={`${data.icon} fa-lg`}
            ></i>
          ) : data.image ? (
            <img
              alt=""
              src={data.image}
              style={{
                height: '23px',
                width: '23px',
                marginRight: '3px',
                ...data.styleImage
              }}
            ></img>
          ) : null}
          &nbsp;
          <Typography sx={{ fontWeight: 500, color: 'text.secondary' }}>
            {data.label}
          </Typography>
        </div>
      </Button>
    );
  };
  const identifyPlaceShare = (data: any) => {
    if (share || shareNow || data?.reblog_of_id) {
      if (match?.path === '/') {
        if (
          (data?.group_id ||
            data?.page_id ||
            data?.target_account_id ||
            data?.shared_group_id ||
            data?.shared_page_id) &&
          shareTo !== undefined
        ) {
          return 'shareAtGroupPageUser';
        } else return 'shareAtFeed';
      } else {
        return 'shareAtNotFeed';
      }
    } else return 'post';
  };

  const checkAdmin = () => {
    //function for created by page
    if (
      !eventInfo?.page_owner &&
      !projectInfo?.page_owner &&
      !courseInfo?.page_owner
    )
      return false;

    if (
      eventInfo?.event_relationship?.host_event ||
      eventInfo?.page_owner?.page_relationship?.role === 'admin' ||
      eventInfo?.page_owner?.page_relationship?.role === 'moderator'
    )
      return true;

    if (
      projectInfo?.project_relationship?.host_project ||
      projectInfo?.page_owner?.page_relationship?.role === 'admin' ||
      projectInfo?.page_owner?.page_relationship?.role === 'moderator'
    )
      return true;

    if (
      courseInfo?.course_relationships?.host_course ||
      courseInfo?.page_owner?.page_relationship?.role === 'admin' ||
      courseInfo?.page_owner?.page_relationship?.role === 'moderator'
    )
      return true;
  };

  const handleDataPost = data => {
    //form data for post created in event, project, course of page(has page_owner)
    if (checkAdmin())
      return {
        ...data,
        status_activity_id: activitiesSelected?.id || null,
        page_owner_id:
          eventInfo?.page_owner?.id ??
          projectInfo?.page_owner?.id ??
          courseInfo?.page_owner?.id
      };

    if (shareTo === 'page') {
      return {
        ...data,
        page_owner_id: data.page_id,
        status_activity_id: activitiesSelected?.id || null
      };
    } else {
      if (activitiesSelected?.title) {
        return {
          ...data,
          status_activity_id: parentSelected?.id,
          tagable_page_id: activitiesSelected?.id || null,
          page_owner_id: rolePage?.page_owner_id,
          page_id: rolePage?.page_owner_id
        };
      } else if (shareTo === 'group') {
        //Chia sẻ các event, recruit, grow, course vào group không truyền lên page_id và page_owner_id
        return {
          ...data,
          page_owner_id: null,
          status_activity_id: activitiesSelected?.id || null
        };
      } else if (rolePage?.page_owner_id) {
        return {
          ...data,
          status_activity_id: activitiesSelected?.id || null,
          page_owner_id: rolePage?.page_owner_id,
          page_id: rolePage?.page_owner_id
        };
      } else {
        return {
          ...data,
          status_activity_id: activitiesSelected?.id || null,
          group_relationship: groupSelected?.group_relationship
        };
      }
    }
  };

  const handleActionCreatePost = (
    data: any,
    place: any = null,
    setState: any = null
  ) => {
    try {
      if (
        !allowDiscussion &&
        courseInfo?.id &&
        !courseInfo?.course_relationships.host_course
      ) {
        dispatch(
          notiSharePost({
            code: 500,
            msg: 'Khoá học này không được đăng bài thảo luận'
          })
        );
        return;
      }
      const dataCreatePost = handleDataPost(data);

      dispatch(resetPostLoad());
      if (dataCreatePost.id && !dataCreatePost.reblog_of_id) {
        try {
          dispatch(updatePostRequestAction(dataCreatePost));
        } catch (error: any) {
          console.log(error);
        }
      } else {
        try {
          dispatch(
            createPostRequestAction(
              dataCreatePost,
              type,
              identifyPlaceShare(data),
              placeCreatePost,
              setState,
              increment_reblog_count
            )
          );
          setOpenNoti(true);
        } catch (error: any) {
          console.log(error);
          setOpenAl &&
            setOpenAl({
              text: error.response?.data?.error,
              boolean: true,
              code: 500
            });
        }
      }
      if (handleFinal) {
        handleFinal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    if (isLoad === 'success' && errorPost?.code === undefined) {
      setTextContent('');
      setActivitiesSelected(null);
      setParentSelected(null);
      setMenuSelected([]);
      dispatch(updateIsLoad(null));
    }
  }, [isLoad]);

  // server save position as string '1,2,3', each position needs to be convert into a separate object
  const handleConvertObjectMentionServerToClient = objectMentions => {
    try {
      let objectMentionsAfterSplit = _.cloneDeep(objectMentions).map(el => {
        let positionAfterSplit = el.position?.split(',').map(el => Number(el));
        el.position = positionAfterSplit;
        return el;
      });
      let objectMentionsAfterSeparate: any[] = [];
      objectMentionsAfterSplit.map(el => {
        if (el.position.length === 1) {
          objectMentionsAfterSeparate.push({
            type: el.entity_type,
            id: el.entity_id,
            name: el.name,
            position: el.position[0]
          });
        } else if (el.position.length > 1) {
          el.position.map((_el, index) => {
            objectMentionsAfterSeparate.push({
              type: el.entity_type,
              id: el.entity_id,
              name: el.name,
              position: el.position[index]
            });
          });
        } else {
        }
        setObjectMentions(objectMentionsAfterSeparate);
      });
    } catch (error) {}
  };

  React.useEffect(() => {
    if (
      post &&
      (type === 'update' || type === 'update_product') &&
      openCUPost
    ) {
      if (post.status_tags && post.status_tags.length) {
        // convert format [id] to @name to update post
        handleConvertObjectMentionServerToClient(post.status_tags);
        let newText: any = post;
        const regexp = /\[\d+\]/gm;
        newText = newText.content.replace(regexp, userIdMention => {
          const objectMentions = post.status_tags.find(
            el =>
              el.entity_id === userIdMention.slice(1, userIdMention.length - 1)
          );

          return (
            `@[${objectMentions?.name}](${objectMentions?.entity_id})` ??
            userIdMention
          );
        });
        setTextContent(newText);
      } else {
        setTextContent(post.content);
      }

      if (!parentSelected || !activitiesSelected) {
        setActivitiesSelected(post.status_activity);
        setParentSelected(post.status_activity?.parent ?? post.status_activity);
      }
    }
  }, [type, JSON.stringify(post), openCUPost]);

  React.useEffect(() => {
    if (shareNow === true) {
      handlePostShareNow();
    }
  }, [shareNow]);

  React.useEffect(() => {
    if (match.path === PATHS.LIVESTREAM_CREATE) {
      setMenuSelected(['live']);
    }
  }, [match.path]);

  const renderLinkAvatar = () => {
    if (checkAdmin())
      return (
        courseInfo?.page_owner?.avatar_url ??
        projectInfo?.page_owner?.avatar_media?.url ??
        eventInfo?.page_owner?.avatar_media?.url
      );

    if (pageInteract?.id && rolePage?.page_owner_id) {
      return pageInteract?.avatar_media;
    } else {
      return meInfo?.avatar_media ?? meInfo?.avatar_static;
    }
  };

  const renderText = () => {
    if (checkAdmin())
      return `${(
        courseInfo?.page_owner?.title ??
        projectInfo?.page_owner?.title ??
        eventInfo?.page_owner?.title
      )
        .trim()
        .split(' ')
        .pop()} ơi, bạn đang nghĩ gì thế?`;

    if (pageInfo?.id && type === 'livestream') {
      return 'Video trực tiếp của bạn nói về điều gì?';
    } else if (pageInteract?.id && rolePage?.page_owner_id) {
      return `${(pageInteract?.title)
        .split(' ')
        .pop()} ơi, bạn đang nghĩ gì thế?`;
    } else {
      return `${(meInfo?.display_name ?? meInfo?.username)
        .split(' ')
        .pop()} ơi, bạn đang nghĩ gì thế?`;
    }
  };

  return Object.keys(meInfo).length ? (
    <>
      {type === 'product' ? null : post ||
        type === 'header-post' ||
        type === 'header-post-page' ||
        typePost === 'only-post' ? null : pageInfo?.id &&
        rolePage?.role &&
        type !== 'livestream' ? (
        <Box
          className={classes.message_sender}
          sx={{ backgroundColor: 'background.primary', ...styleLiveStream }}
        >
          <div className={classes.message_sender_top}>
            <AvatarSocial
              type="feed"
              avatarObj={pageInfo?.avatar_media}
              style={{ width: 40, height: 40 }}
            />
            <Box
              className={classes.buttonCreatePost}
              onClick={() => {
                setOpenCUPost(true);
                setSchedulePost(null);
              }}
              style={{ justifyContent: 'center' }}
              sx={{
                backgroundColor: 'background.secondary',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: 'background.hover'
                }
              }}
            >
              <b style={{ fontWeight: 600 }}>Tạo bài viết</b>
            </Box>
          </div>
          <Divider />
          <div className={classes.message_sender_bottom}>
            {(listMenuLiveStream ?? listMenuRender)?.map((item: any, index) =>
              renderButton(item, index)
            )}
          </div>
        </Box>
      ) : (
        <Box
          className={classes.message_sender}
          sx={{ backgroundColor: 'background.primary', ...styleLiveStream }}
        >
          <div className={classes.message_sender_top}>
            <AvatarSocial
              type="feed"
              avatarObj={renderLinkAvatar()}
              style={{ width: 40, height: 40 }}
            />
            <Box
              className={classes.buttonCreatePost}
              onClick={() => setOpenCUPost(true)}
              sx={{
                backgroundColor: 'background.secondary',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: 'background.hover'
                }
              }}
            >
              <Typography>{renderText()}</Typography>
            </Box>
          </div>
          {type === 'livestream' && <Typography>{textContentLive}</Typography>}
          <Divider />
          <div className={classes.message_sender_bottom}>
            {(listMenuLiveStream ?? listMenuRender)?.map((item: any, index) =>
              renderButton(item, index)
            )}
          </div>
        </Box>
      )}

      {openCUPost && (
        <DialogCUPost
          type={type}
          post={post}
          open={openCUPost}
          setOpen={setOpenCUPost}
          menuSelected={menuSelected}
          setMenuSelected={setMenuSelected}
          setOpenDialogAE={setOpenDialogAE}
          activitiesSelected={activitiesSelected}
          parentSelected={parentSelected}
          openUploadMedia={openUploadMedia}
          setOpenUploadMedia={setOpenUploadMedia}
          textContent={textContent}
          setTextContent={setTextContent}
          setNotificationLeave={setNotificationLeave}
          handleClose={() => {
            dispatch(resetErrorPost());
            setOpenCUPost(false);
            setUpdateTags && setUpdateTags(false);
            dispatch(openDialogPost('close'));
          }}
          handleActionCreatePost={handleActionCreatePost}
          pageInfo={pageInfo}
          eventInfo={eventInfo}
          projectInfo={projectInfo}
          project={project}
          recruitInfo={recruitInfo}
          recruit={recruit}
          courseInfo={courseInfo}
          course={course}
          group={group}
          page={page}
          event={event}
          music={music}
          product={product || post?.share_product_or_course}
          groupShared={type === PAGE_TYPE.group ? groupSelected : groupShared}
          pageShared={pageShared}
          uploadVideo={uploadVideo}
          friendShared={friendShared}
          newFiles={newFiles}
          newLifeEvent={newLifeEvent}
          share={share}
          errorPost={errorPost}
          schedulePost={schedulePost}
          setSchedulePost={setSchedulePost}
          setObjectMentions={setObjectMentions}
          objectMentions={objectMentions}
          textContentLive={textContentLive}
          setTextContentLive={setTextContentLive}
          idPostNoti={idPostNoti}
          setIdPostNoti={setIdPostNoti}
          updateTags={updateTags}
          setUpdateTags={setUpdateTags}
          shareTo={shareTo}
          groupSelected={groupSelected}
          setSharePostNow={setSharePostNow}
          sharePostInNewFeed={sharePostInNewFeed}
          setSharePostInNewFeed={setSharePostInNewFeed}
          isSharePost={isSharePost}
        />
      )}

      {openDialogAE && (
        <DialogShowActivitiesAction
          open={openDialogAE}
          handleClose={() => {
            setOpenDialogAE(false);
            setOpenCUPost(true);
          }}
          parentSelected={parentSelected}
          setParentSelected={setParentSelected}
          activitiesSelected={activitiesSelected}
          setActivitiesSelected={setActivitiesSelected}
        />
      )}
      {openDialogMoment && (
        <DialogCUMoment
          type={type}
          projectInfo={projectInfo}
          courseInfo={courseInfo}
          eventInfo={eventInfo}
          groupId={groupSelected?.id}
          groupTitle={groupSelected.title}
          groupVisibility={groupSelected?.is_private}
          pageOwnerId={pageInfo?.id}
          open={openDialogMoment}
          setOpen={setOpenDialogMoment}
          handleClose={() => setOpenDialogMoment(false)}
        />
      )}
      {openTagFriend && (
        <DialogTagFriend
          open={openTagFriend}
          handleClose={() => {
            setOpenCUPost(true);
            setOpenTagFriend(false);
          }}
          friendSelected={friendSelected}
          setFriendSelected={setFriendSelected}
        />
      )}
      {notificationLeave && (
        <CustomPrompt
          when={notificationLeave}
          navigate={path => {
            history.push(path);
            //Component CUPost không unMount và notificationLeave không được set về false nên luôn hiển thị
            setNotificationLeave(false);
          }}
          shouldBlockNavigation={() => {
            return notificationLeave;
          }}
          title={'Rời khỏi trang?'}
          content={
            'Bài viết của bạn chưa được lưu thông tin, bạn có xác nhận rời khỏi không?'
          }
          optionDialog={2}
          labelButton={['Ở lại', 'Rời khỏi']}
        />
      )}
      {openLocation && (
        <DialogLocation
          open={openLocation}
          handleClose={() => {
            setOpenCUPost(true);
            setOpenLocation(false);
          }}
          locationSelected={locationSelected}
          setLocationSelected={setLocationSelected}
          type="post"
        />
      )}
      {openNoti && type === 'product' && <NotiPost />}
    </>
  ) : null;
};

export default CUPost;
