interface DataUser {
  entity_id: string;
  name: string;
}

export const convertStrings = (text: any) => {
  if (!text) return 'Chưa có thông tin';
  const match = /\r|\n/.exec(text);
  if (match) {
    return text.replace(/\r\n/g, '<br/>');
  }
  return text;
};

export const convertIdToName: (
  content: string,
  listDataUser: Array<DataUser>
) => string = (content: string, listDataUser: Array<DataUser>) => {
  const regex = /\[(\d+)\]/g;

  // Sử dụng replace với callback để thay thế ID trong chuỗi
  content = content.replace(regex, (match, p1) => {
    const user = listDataUser.find(e => e.entity_id === p1);
    return user ? user.name : match;
  });
  return content;
};
