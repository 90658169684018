import { Typography } from '@material-ui/core';
import { ClickAwayListener } from '@mui/base';
import {
  Box,
  Chip,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Popper,
  Stack
} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton/ListItemButton';
import { useTheme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import iconCoin from 'src/assets/images/icon-coin.svg';
import ButtonCustom from 'src/components/Button/Button';
import DialogConfirmPayment from 'src/components/Dialog/DialogConfirmDelete';
import {
  chipDataLine1,
  chipDataLine2,
  exchangeData
} from 'src/constants/common';
import { handleEcoin, handlePrice } from 'src/helpers/string';
import ButtonInherit from '../Button/ButtonInherit';
import SocialCutImage from '../SocialCutImage/SocialCutImage';
import { useHistory } from 'react-router-dom';
import { buttonColor } from 'src/constants/styles';
import { getBalance } from 'src/apis/socialPayment.api';

interface Props {
  anchorEl?: any;
  handleClosePayment?: any;
  confirmPayment?: any;
  price?: any;
  subsystem?: any;
  action?: any;
  setOpenDialogPaymentDetail?:
    | React.Dispatch<React.SetStateAction<boolean>>
    | any;
  exchangeSelected?: any;
  discountData?: any;
  setExchangeSelected?: React.Dispatch<React.SetStateAction<any>> | any;
  data?: any;
  statusPayment?: any;
  type?: String;
  rechargeNow?: any;
  checkDonate?: any;
  infoDonate?: any;
  loading?: boolean;
  setLoading?: React.Dispatch<React.SetStateAction<any>> | any;
  entity_type?: 'Status' | undefined;
}

interface type_chip {
  key: number;
  price: number;
  amount: number;
}

function PaymentPopup(props: Props) {
  let {
    anchorEl,
    handleClosePayment,
    confirmPayment,
    price,
    subsystem,
    action,
    setOpenDialogPaymentDetail,
    exchangeSelected,
    discountData,
    setExchangeSelected,
    data,
    statusPayment,
    type,
    rechargeNow,
    checkDonate,
    infoDonate,
    loading,
    setLoading,
    entity_type
  } = props;
  const useStyles = makeStyles(
    (theme: any) =>
      createStyles({
        root: {
          width: '356px',
          height: '380px',
          backgroundColor: theme.palette.background.primary,
          borderRadius: '8px',
          boxShadow: theme.palette.boxShadow.primary
        },
        popper: {
          zIndex: 100000
        },
        lineClamp: {
          display: '-webkit-box',
          WebkitLineClamp: '2',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden'
        },
        wrapPrice: {
          display: 'inline-flex',
          alignItems: 'center'
        },
        textPrice: {
          fontSize: '15px !important',
          fontWeight: '600 !important'
        },
        coinIcon: {
          marginLeft: '5px'
        },
        stackChip: {
          display: 'flex',
          flexWrap: 'wrap',
          padding: '10px 10px 10px 15px'
        },
        wrapTitle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '64px',
          padding: '12px'
        },
        wrapConfirmPayment: {
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        },
        listConfirm: {
          padding: '8px 12px !important',
          width: '100%'
        },
        wrapPayPrice: {
          borderRadius: '8px !important',
          backgroundColor: `${theme.palette.button.secondary.background} !important`,
          height: '40px'
        },
        listItemTextConfirm: {
          '& .MuiListItemText-primary': {
            fontSize: '15px',
            fontWeight: '600'
          }
        },
        rootBalance: {
          padding: '10px 10px 10px 15px'
        },
        wrapBalance: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          backgroundColor: theme.palette.button.secondary.background,
          borderRadius: '8px',
          height: '100px',
          marginBottom: '10px'
        },
        wrapTextBalance: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        },
        textBalance: {
          fontSize: '14px',
          fontWeight: 'bold'
        },
        note: {
          fontSize: '12px',
          fontWeight: '400',
          color: theme.palette.text.secondary
        },
        input: {
          height: '40px',
          borderRadius: '50px!important',
          '& .MuiOutlinedInput-input': {
            textAlign: 'center'
          }
        },
        wrapAction: {
          height: '68px',
          position: 'absolute',
          bottom: '0px',
          borderTop: theme.palette.border.light,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px 12px'
        },
        wrapDescription: {
          padding: '0px 10px 0px 15px'
        },
        wrapContent: {
          width: '100%',
          height: '212px',
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            width: '10px'
          },
          '&::-webkit-scrollbar-track': {
            background: theme.palette.mode === 'light' ? '#e5e5e5' : '#2c2c2c'
          },
          '&::-webkit-scrollbar-thumb': {
            background: theme.palette.mode === 'light' ? '#bdbdbd' : '#6b6b6b',
            borderRadius: '100px'
          }
        },
        listItemTextPurchased: {
          '& .MuiListItemText-primary': {
            color: '#fff',
            fontSize: '15px',
            fontWeight: '600'
          }
        }
      }),
    { index: 1 }
  );
  const classes = useStyles();
  const theme = useTheme();
  const openPopper = Boolean(anchorEl);
  const [step, setStep] = React.useState<any>(rechargeNow ? 1 : 0);
  const [chipSelected, setChipSelected] = React.useState<type_chip | null>(
    null
  );
  const [openDialogConfirmPayment, setOpenDialogConfirmPayment] =
    React.useState<any>(false);
  const [showInput, setShowInput] = React.useState<any>(false);
  const [isError, setIsError] = React.useState<any>(false);
  const [balance, setBalance] = React.useState<any>('');
  const meInfo = useSelector((state: any) => state.meReducer.info);
  const id = openPopper ? 'simple-popper' : undefined;
  const history = useHistory();

  React.useEffect(() => {
    if (meInfo?.id) {
      getBalanceUser();
    }
  }, [meInfo?.id]);

  const getBalanceUser = async () => {
    let res = await getBalance();
    if (res.status === 200) {
      setBalance(() => res.data);
    }
  };
  const handleKeyPress = event => {
    const charCode = event.which || event.keyCode;
    // Kiểm tra xem phím được nhấn có phải là số không
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  const renderPrice = (text: any) => {
    return (
      <Box className={classes.wrapPrice}>
        <Typography
          className={classes.textPrice}
          style={{ color: text === 'Nạp' ? '#000' : 'unset' }}
        >
          {!isNaN(text) ? handleEcoin(text) : text}
        </Typography>
        <SocialCutImage
          image={iconCoin}
          item={{
            positionX: 0,
            positionY:
              text === 'Nạp' ? -32 : theme.palette.mode === 'dark' ? -65 : -32
          }}
          zoom={0.6}
          style={{ height: '41px', width: '41px', margin: '5px 0px 0px 5px' }}
        />
      </Box>
    );
  };

  const renderChip = list => {
    return (
      <Stack direction="row" spacing={1} className={classes.stackChip}>
        {list.map(data => {
          return (
            <Chip
              color={chipSelected?.key === data.key ? 'primary' : 'default'}
              key={data.key}
              icon={
                <i
                  style={{ fontSize: '15px', fontWeight: '600' }}
                  className="fa-sharp fa-solid fa-coins"
                ></i>
              }
              label={
                <Typography className={classes.textPrice}>
                  {handleEcoin(data.amount)}
                </Typography>
              }
              sx={{
                width: '104px',
                height: '40px',
                borderRadius: '50px',
                border:
                  chipSelected?.key === data.key
                    ? `2px solid ${buttonColor.backgroundColor}`
                    : 'unset'
              }}
              variant={chipSelected?.key === data.key ? 'outlined' : 'filled'}
              onClick={() => setChipSelected(() => data)}
            />
          );
        })}
      </Stack>
    );
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClosePayment}>
        <Popper
          className={classes.popper}
          id={id}
          open={openPopper}
          anchorEl={anchorEl}
          placement={'top-end'}
        >
          <Box className={classes.root}>
            {step === 0 && !type ? (
              <Box>
                <Grid container xs={12}>
                  {/* Title */}
                  <Grid item xs={12} className={classes.wrapTitle}>
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.lineClamp}
                          style={{
                            fontSize: '19px',
                            fontWeight: '700',
                            width: '200px'
                          }}
                        >
                          {confirmPayment
                            ? 'Xác nhận thanh toán'
                            : `Tặng Xu để ủng hộ ${data?.title}`}
                        </Typography>
                      }
                    />

                    <ButtonCustom
                      label={renderPrice('Nạp')}
                      style={{
                        width: '76.45px',
                        height: '36px',
                        borderRadius: '18px',
                        padding: '0px 12px',
                        backgroundColor: '#fef2d1'
                      }}
                      action={() => {
                        history.push('/emso_payment/payment_coin');
                      }}
                    />
                  </Grid>
                  {/* Content */}
                  {confirmPayment ? (
                    <Box className={classes.wrapConfirmPayment}>
                      <Grid container item xs={12}>
                        <List className={classes.listConfirm}>
                          <ListItemButton
                            disableGutters
                            className={classes.wrapPayPrice}
                          >
                            <ListItem
                              secondaryAction={
                                <ListItemText
                                  className={classes.listItemTextConfirm}
                                  primary={
                                    price ? renderPrice(price / 1000) : 0
                                  }
                                />
                              }
                            >
                              <ListItemText
                                className={classes.listItemTextConfirm}
                                primary="Số tiền phải trả"
                              />
                            </ListItem>
                          </ListItemButton>
                        </List>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        className={classes.rootBalance}
                      >
                        <Box className={classes.wrapBalance}>
                          <Typography className={classes.textPrice}>
                            {balance.balance - price < 0 || !price
                              ? null
                              : 'Số dư'}
                          </Typography>
                          <Box className={classes.wrapTextBalance}>
                            <Typography className={classes.textBalance}>
                              {price
                                ? balance?.balance
                                  ? balance.balance - price < 0
                                    ? 'Tài khoản của bạn không đủ để thanh toán'
                                    : handleEcoin(
                                        (balance.balance - price) / 1000
                                      )
                                  : null
                                : 'Tài khoản của bạn không đủ để thanh toán'}
                            </Typography>
                            {balance?.balance ? (
                              balance.balance - price < 0 || !price ? null : (
                                <SocialCutImage
                                  image={iconCoin}
                                  item={{
                                    positionX: 0,
                                    positionY:
                                      theme.palette.mode === 'dark' ? -65 : -32
                                  }}
                                  zoom={0.7}
                                  style={{
                                    height: '41px',
                                    width: '41px',
                                    marginLeft: '5px'
                                  }}
                                />
                              )
                            ) : null}
                          </Box>
                        </Box>
                        <Typography className={classes.note}>
                          Lưu ý: Tỷ lệ quy đổi 1 E-XU thành 1,000 VNĐ
                        </Typography>
                      </Grid>
                    </Box>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        {renderChip(chipDataLine1)}
                        {renderChip(chipDataLine2)}
                      </Grid>
                      <Grid item xs={12} className={classes.rootBalance}>
                        {showInput ? (
                          <>
                            <ClickAwayListener
                              onClickAway={() => {
                                if (!chipSelected?.amount) setShowInput(false);
                              }}
                            >
                              <OutlinedInput
                                autoFocus
                                error={isError}
                                color="primary"
                                fullWidth
                                className={classes.input}
                                onKeyPress={handleKeyPress}
                                onChange={(event: any) => {
                                  if (event.target.value < 10) {
                                    setIsError(true);
                                    setChipSelected(null);
                                  } else {
                                    setChipSelected(() => ({
                                      key: 6,
                                      amount: event.target.value,
                                      price: event.target.value * 1000
                                    }));
                                    setIsError(false);
                                  }
                                }}
                                aria-describedby="error-text"
                              />
                            </ClickAwayListener>
                            {isError ? (
                              <FormHelperText error id="error-text">
                                Vui lòng nhập ít nhất 10 E-XU
                              </FormHelperText>
                            ) : null}
                          </>
                        ) : (
                          <ButtonInherit
                            label="Tùy chỉnh"
                            fullWidth
                            style={{ borderRadius: '50px', height: '40px' }}
                            action={() => {
                              setShowInput(true);
                              setChipSelected(() => null);
                            }}
                          />
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
                {/* Action */}
                <Grid container className={classes.wrapAction}>
                  {balance?.balance ? (
                    balance?.balance < price ||
                    balance?.balance < (chipSelected?.amount || 10) ||
                    balance?.balance < 10 ? (
                      <ButtonInherit
                        label="Nạp E-XU"
                        fullWidth
                        action={() => {
                          history.push('/emso_payment/payment_coin');
                        }}
                        color={buttonColor.backgroundColor}
                        style={{ margin: '0px' }}
                      />
                    ) : (
                      <ButtonInherit
                        label={
                          statusPayment === 'donate_project'
                            ? 'Ủng hộ'
                            : statusPayment === 'invest_project'
                            ? 'Đầu tư'
                            : 'Thanh toán'
                        }
                        fullWidth
                        action={() => {
                          setOpenDialogConfirmPayment(true);
                        }}
                        color={buttonColor.backgroundColor}
                        style={{ margin: '0px' }}
                        disabled={chipSelected ? false : true}
                      />
                    )
                  ) : (
                    <ButtonInherit
                      label="Nạp E-XU"
                      fullWidth
                      action={() => {
                        history.push('/emso_payment/payment_coin');
                      }}
                      color={buttonColor.backgroundColor}
                      style={{ margin: '0px' }}
                    />
                  )}
                </Grid>
              </Box>
            ) : step === 1 || type ? (
              <Box>
                <Grid container xs={12}>
                  {/* Title */}
                  <Grid item xs={12} className={classes.wrapTitle}>
                    {!rechargeNow ? (
                      <IconButton
                        aria-label="close"
                        onClick={() => setStep(0)}
                        sx={{
                          height: 36,
                          width: 36,
                          color: theme => theme.palette.grey[500],
                          backgroundColor: '#e5e5e5'
                        }}
                      >
                        <i
                          className="fa-regular fa-arrow-left-long"
                          style={{ fontSize: 18 }}
                        ></i>
                      </IconButton>
                    ) : null}

                    <Typography
                      className={classes.lineClamp}
                      style={{
                        fontSize: '19px',
                        fontWeight: '700',
                        width: '200px'
                      }}
                    >
                      Thêm E-XU vào số dư
                    </Typography>
                    <ButtonCustom
                      disabled
                      label={renderPrice('Nạp')}
                      style={{
                        width: '76.45px',
                        height: '36px',
                        borderRadius: '18px',
                        padding: '0px 12px',
                        backgroundColor: '#fef2d1'
                      }}
                    />
                  </Grid>
                  <Box className={classes.wrapDescription}>
                    <Typography className={classes.note}>
                      E-XU bạn nạp ở đây sẽ được giữ trong số dư. Bạn có thể
                      tặng E-XU từ số dư bất cứ lúc nào.
                    </Typography>
                  </Box>

                  {/* Content */}
                  <Box className={classes.wrapConfirmPayment}>
                    <Grid container item xs={12}>
                      <Box className={classes.wrapContent}>
                        {discountData.map((data: any, index) => {
                          return (
                            <List
                              className={classes.listConfirm}
                              key={`${data.key}+${index}`}
                            >
                              <ListItemButton
                                disableGutters
                                sx={{
                                  borderRadius: '8px',
                                  backgroundImage: `url("https://png.pngtree.com/background/20211217/original/pngtree-floating-gold-coin-casino-light-effect-background-picture-image_1597224.jpg")`,
                                  backgroundSize: 'cover',
                                  backgroundPosition: 'center',
                                  height: '40px',
                                  outline:
                                    exchangeSelected?.key === data.key
                                      ? `2px solid ${buttonColor.backgroundColor}`
                                      : 'unset'
                                }}
                                onClick={() => setExchangeSelected(data)}
                              >
                                <ListItem
                                  key={data.key}
                                  secondaryAction={
                                    <ListItemText
                                      className={classes.listItemTextPurchased}
                                      primary={handlePrice(data.price)}
                                    />
                                  }
                                >
                                  <ListItemText
                                    className={classes.listItemTextPurchased}
                                    primary={`${handleEcoin(data.amount)} E-XU`}
                                  />
                                </ListItem>
                              </ListItemButton>
                            </List>
                          );
                        })}
                        <Typography
                          className={classes.note}
                          style={{
                            padding: '0px 15px 10px 15px'
                          }}
                        >
                          Giảm giá khi nạp E-XU lần đầu! Thời gian có hạn!
                        </Typography>
                        {exchangeData.map((data: any, index) => {
                          return (
                            <List
                              className={classes.listConfirm}
                              key={`${index}+${data.key}`}
                            >
                              <ListItemButton
                                disableGutters
                                sx={{
                                  borderRadius: '8px',
                                  backgroundColor:
                                    theme.palette.mode === 'dark'
                                      ? '#3a3b3c'
                                      : '#eef0f5',
                                  height: '40px',
                                  outline:
                                    exchangeSelected?.key === data.key
                                      ? `2px solid ${buttonColor.backgroundColor}`
                                      : 'unset'
                                }}
                                onClick={() => setExchangeSelected(data)}
                              >
                                <ListItem
                                  key={data.key}
                                  secondaryAction={
                                    <ListItemText
                                      className={classes.listItemTextConfirm}
                                      primary={handlePrice(data.price)}
                                    />
                                  }
                                >
                                  <ListItemText
                                    className={classes.listItemTextConfirm}
                                    primary={`${handleEcoin(data.amount)} E-XU`}
                                  />
                                </ListItem>
                              </ListItemButton>
                            </List>
                          );
                        })}
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
                {/* Action */}
                <Grid container className={classes.wrapAction}>
                  <ButtonInherit
                    label={`Nạp ${handleEcoin(
                      exchangeSelected?.amount
                    )} E-XU với giá ${handlePrice(exchangeSelected?.price)}`}
                    fullWidth
                    action={() => {
                      handleClosePayment();
                      setOpenDialogPaymentDetail(true);
                    }}
                    color={buttonColor.backgroundColor}
                    style={{ margin: '0px' }}
                  />
                </Grid>
              </Box>
            ) : null}
          </Box>
        </Popper>
      </ClickAwayListener>
      <DialogConfirmPayment
        title="Xác nhận thanh toán"
        contentDialog={renderPrice(
          confirmPayment
            ? `Bạn có chắc chắn mua ${subsystem} với giá ${handleEcoin(
                price / 1000
              )}`
            : `Bạn có chắc chắn muốn ủng hộ ${subsystem} với ${handleEcoin(
                chipSelected?.amount || 0
              )} `
        )}
        open={openDialogConfirmPayment}
        handleClose={() => setOpenDialogConfirmPayment(false)}
        action={() => {
          if (action && statusPayment === '') {
            action();
          } else {
            if (statusPayment === 'donate') {
              action(
                infoDonate?.page_owner
                  ? {
                      amount: chipSelected?.amount,
                      page_id: infoDonate?.page_owner?.id,
                      entity_id: infoDonate?.id,
                      entity_type:
                        infoDonate?.media?.type === 'audio' ? 'Music' : 'Status'
                    }
                  : {
                      amount: chipSelected?.amount,
                      target_account_id: infoDonate?.account?.id,
                      entity_id: infoDonate?.id,
                      entity_type: entity_type
                    }
              );
            } else {
              action({
                amount:
                  checkDonate === 'donateGrow'
                    ? chipSelected?.amount
                    : chipSelected?.price,
                detail_type: statusPayment
              });
            }
          }
        }}
        labelButton={
          statusPayment === 'donate_project'
            ? 'Ủng hộ'
            : statusPayment === 'invest_project'
            ? 'Đầu tư'
            : 'Thanh toán'
        }
        loading={loading}
        setLoading={setLoading}
        styleDialog={{
          zIndex: 100001
        }}
      />
    </>
  );
}

export default PaymentPopup;
