import {
  Box,
  CircularProgress,
  IconButton,
  Slider,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import {
  postSelectedAction,
  watchSaveTimeRun
} from 'src/store/action/socialWatchAction';
import MediaDetail from 'src/pages/PageInfomation/MediaDetail';
import ReactPlayer from 'react-player';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import SettingVideo from './SettingVideo';
import { formatDuration } from 'src/helpers/common';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { useInView } from 'react-hook-inview';
import { postWatchHistoriesApi } from 'src/apis/socialPost.api';

interface Props {
  src?: any;
  previewMedia?: any;
  id?: String | any;
  postId?: String | any;
  action?: any;
  isLiveStream?: boolean | any;
  processingLiveStream?: any;
  type?: String | any;
  duration?: number;
  closeDialogVideo?: boolean; // Bắt sự kiện khi đóng dialog thì đẩy thời gian video lên
  openDialogVideo?: boolean; // Bắt sự kiện khi bật video dialog
  muted?: boolean;
  offset?: number;
  autoPlay?: boolean;
}

const ReactPlayerVideo = (props: Props) => {
  const {
    src,
    previewMedia,
    id,
    postId,
    action,
    isLiveStream,
    processingLiveStream,
    type,
    duration = 0,
    closeDialogVideo,
    openDialogVideo,
    muted,
    offset = 0,
    autoPlay = true
  } = props;
  const videoRef: any = React.useRef();
  const boxVideoRef: any = React.useRef();
  const boxImageRef: any = React.useRef();
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();

  const liveStreamSocket = useSelector(
    (state: any) => state.socialLiveStreamReducer.liveStreamSocket
  );

  const watchSave = useSelector(
    (state: any) => state.watchReducer.watchSaveTimeRun
  );
  const location = useLocation();

  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  const [playbackRate, setPlaybackRate] = React.useState(1);
  const [loaded, setLoaded] = React.useState(0);
  const [volume, setVolume] = React.useState(1);
  const [played, setPlayed] = React.useState(0);
  const [hoverVolume, setHoverVolume] = React.useState(false);
  const [centerView, setCenterView] = React.useState<any>(true); // Dùng để check xem video có cắt đường kẻ ngang giữa màn hình không
  const [incremented, setIncremented] = useState(false); //Check đã tăng số lượt xem hay chưa
  const [showControl, setShowControl] = React.useState(false);
  const [previousVideoState, setPreviousVideoState] = React.useState(true); // Lưu lại trạng thái play video trước đó
  const [hasOverlayDialog, setHasOverlayDialog] = React.useState(false); // Dùng để check trường hợp click bật ra dialog thì video ở dưới dialog k play
  const [ref, isVisible] = useInView({
    threshold: 0.6,
    rootMargin: '50%',
    root:
      openDialogVideo == true ? document.getElementById('scroll-watch') : null // Trong trường hợp vào detail list watch thì tính theo div này
  });
  let idVideo = React.useRef<any>(0); // Kiểm tra xem có dialog hay không
  const searchParams = new URLSearchParams(location.search);
  const watchId = searchParams.get('v');
  const mediaId = searchParams.get('mediaId');

  React.useEffect(() => {
    idVideo.current = watchId;
  }, [watchId]);

  React.useEffect(() => {
    if ((played * 100) / duration > 30 && !incremented) {
      setIncremented(true);
      postWatchHistoriesApi({
        action_type: 'view_3s',
        status_ids: [postId]
      });
    }
  }, [duration, played]);

  const handleVisibilityChange = () => {
    // Kiểm tra nếu tab bị chuyển đi (trở thành "hidden" state)
    if (document.visibilityState === 'hidden') {
      // Kiểm tra nếu video đang phát, thì tạm dừng nó
      setPlaying(false);
    } else {
      if (!hasOverlayDialog && autoPlay) {
        setPlaying(isCentered());
      }
    }
  };

  // Dùng để check khi chuyển sang tab khác thì k play nữa
  React.useEffect(() => {
    // Đăng ký event listener cho sự kiện khi tab được chuyển
    if (previousVideoState && isVisible) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    } else {
      // Huỷ đăng ký event listener khi previousVideoState là false
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
    return () => {
      // Hủy đăng ký event listener khi component bị unmount
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [previousVideoState, isVisible, hasOverlayDialog]);

  // Bắt sự kiện thay đổi fullscreen
  React.useEffect(() => {
    if (isVisible) {
      window.addEventListener('fullscreenchange', () => {
        if (document.fullscreenElement) {
          setIsFullScreen(true);
        } else {
          setIsFullScreen(false);
        }
      });
    } else {
      window.removeEventListener('fullscreenchange', () => {});
    }

    return window.removeEventListener('fullscreenchange', () => {
      setIsFullScreen(false);
    });
  }, [isVisible]);

  const isCentered = (offset = 0) => {
    const element = boxImageRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const viewHeight = window.innerHeight;
      const halfViewHeight = viewHeight / 2;
      if (offset) {
        return !(
          rect.bot <= halfViewHeight - offset ||
          rect.top >= halfViewHeight + offset
        );
      } else {
        return rect.top <= halfViewHeight && rect.bottom >= halfViewHeight;
      }
    } else {
      return false;
    }
  };

  // Trường hợp dialog k unmount thì sẽ làm như vậy
  React.useEffect(() => {
    if (closeDialogVideo == false) {
      setPlaying(false);
      dispatch(watchSaveTimeRun(id, played, closeDialogVideo));
      setHasOverlayDialog(false);
    }
  }, [closeDialogVideo]);

  React.useEffect(() => {
    if (closeDialogVideo !== true && watchId) {
      setPlaying(false);
      setHasOverlayDialog(true);
    }
  }, []);

  // Khi dialog bị xoá đi thì sẽ gọi như vậy
  const playedRef = React.useRef<number>();
  playedRef.current = played;

  React.useEffect(() => {
    return () => {
      if ((watchId == id || mediaId == id) && closeDialogVideo == true) {
        dispatch(watchSaveTimeRun(id, playedRef.current, false));
      }
    };
  }, []);

  React.useEffect(() => {
    if (openDialogVideo == true) {
      setPlaying(false);
      setPreviousVideoState(false);
      dispatch(watchSaveTimeRun(id, played, openDialogVideo));
      setHasOverlayDialog(true);
    } else if (openDialogVideo == false) {
      // setPreviousVideoState để xử lý trường hợp chuyển tab thì video trên dialog và video trong list đều play
      setPreviousVideoState(true);
      setHasOverlayDialog(false);
    }
  }, [openDialogVideo]);

  // Bắt sự kiện khi tắt popup detail watch
  React.useEffect(() => {
    if (
      watchSave &&
      Object.keys(watchSave).length !== 0 &&
      watchSave.openDialog == false
    ) {
      if (id === watchSave.idWatch) {
        setPlayed(watchSave.timePlay);
        videoRef.current?.seekTo(watchSave.timePlay as number, 'seconds');
      }
      if (previousVideoState && autoPlay) {
        setPlaying(isCentered());
      }
    }
  }, [watchSave]);

  const handleScroll = () => {
    if (isVisible && autoPlay && !idVideo.current) {
      setPlaying(isCentered());
      setCenterView(isCentered(offset));
    }
  };

  const handleScrollDiv = () => {
    if (isVisible && autoPlay) {
      setPlaying(isCentered());
    }
  };

  React.useEffect(() => {
    const divElement = document.getElementById('scroll-watch');
    if (isVisible && autoPlay) {
      if (divElement) {
        divElement.addEventListener('scroll', handleScrollDiv);
      } else {
        window.addEventListener('scroll', handleScroll);
      }
    } else {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScrollDiv);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (divElement) {
        divElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isVisible]);

  useEffect(() => {
    if (muted) {
      setVolume(0);
    } else {
      setVolume(1);
    }
  }, [muted]);

  const handleClickVideo = event => {
    if (autoPlay) setPlaying(!playing);
    if (action) {
      if (!watchId && type === 'watch') {
        if (!isCentered()) action('scroll');
        else action('navigate');
      } else action();
    }
    setHasOverlayDialog(true);
    dispatch(watchSaveTimeRun(id, played, true)); // Lưu thời gian vào store, gửi lên true để biết có bật popup
  };

  const handleProgress = (progress: {
    playedSeconds: number;
    loadedSeconds: number;
  }) => {
    setPlayed(progress.playedSeconds);
    setLoaded(progress.loadedSeconds);
  };

  const handleSeekChange = (event: Event, newValue: number | number[]) => {
    setPlayed(newValue as number);
    videoRef.current.seekTo(newValue as number, 'seconds');
  };

  const handleSetPlaybackRate = value => {
    setPlaybackRate(value);
  };

  const renderVideo = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%'
        }}
        ref={ref}
      >
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
            height: '100%'
          }}
          onMouseEnter={() => {
            setShowControl(true);
          }}
          onMouseLeave={() => {
            setShowControl(false);
          }}
        >
          <img
            ref={boxImageRef}
            style={{
              width: '100%',
              height: '100%',
              // filter: type === 'watch-selected' ? 'blur(80px)' : 'blur(0px)',
              objectFit: 'contain',
              backgroundColor:
                type === 'watch-selected' ? 'rgba(0, 0, 0, 0.5)' : '#000'
            }}
            src={previewMedia}
            alt="Thumbnail"
          />

          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute'
            }}
            ref={boxVideoRef}
          >
            {isVisible && centerView ? (
              <ReactPlayer
                ref={videoRef}
                url={src}
                playing={playing}
                volume={volume}
                playbackRate={playbackRate}
                playsinline
                width="100%"
                height="100%"
                config={{
                  file: {
                    attributes: {
                      poster: previewMedia,
                      style: {
                        width: '100%',
                        height: '100%'
                      }
                    },
                    forceHLS: true,
                    hlsOptions: {
                      startLevel: 3,
                      maxBufferLength: 10,
                      maxBufferSize: 0.5 * 1000 * 1000,
                      backBufferLength: 3,
                      autoStartLoad: true
                    }
                  }
                }}
                // onBuffer={() => {
                //   setLoading(true);
                // }}
                // onBufferEnd={() => {
                //   setLoading(false); // Đặt isLoading thành false khi video sẵn sàng
                // }}
                onEnded={() => {
                  setPlaying(false);
                }}
                onReady={() => {
                  if (autoPlay) {
                    if (isCentered() && !hasOverlayDialog) {
                      setPlaying(true);
                    }
                    if (
                      watchSave &&
                      Object.keys(watchSave).length !== 0 &&
                      id === watchSave.idWatch
                    ) {
                      setPlayed(watchSave.timePlay);
                      videoRef.current?.seekTo(
                        watchSave.timePlay as number,
                        'seconds'
                      );
                    }
                  }
                }}
                onProgress={handleProgress}
              />
            ) : null}
            <Box
              sx={{
                position: 'absolute',
                bottom: '50px',
                left: 0,
                width: '100%',
                height: 'calc(100% - 50px)',
                background: 'rgba(0, 0, 0, 0)',
                padding: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onMouseDown={handleClickVideo}
            >
              {/* {loading && <CircularProgress />} */}
            </Box>

            {!['postMoment'].includes(type) &&
            showControl &&
            videoRef.current?.getInternalPlayer('hls') ? (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  background: 'rgba(0, 0, 0, 0)',
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'end'
                }}
              >
                <IconButton
                  style={{ color: '#fff' }}
                  onMouseDown={() => {
                    setPlaying(!playing);
                    setPreviousVideoState(!playing);
                  }}
                >
                  {playing ? (
                    <PauseIcon fontSize="small" />
                  ) : (
                    <PlayArrowIcon fontSize="small" />
                  )}
                </IconButton>

                <Slider
                  size="small"
                  max={duration || 0}
                  value={played}
                  onChange={handleSeekChange}
                  aria-labelledby="continuous-slider"
                  sx={{
                    marginLeft: '8px',
                    marginBottom: '3px',
                    color: '#fff',
                    height: 4,
                    '& .MuiSlider-thumb': {
                      width: 8,
                      height: 8,
                      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                      '&.Mui-active': {
                        width: 20,
                        height: 20
                      }
                    },
                    '& .MuiSlider-rail': {
                      opacity: 0.28
                    }
                  }}
                ></Slider>
                {duration && (
                  <Typography
                    sx={{
                      marginBottom: '9px',
                      paddingLeft: '8px',
                      fontSize: '0.75rem',
                      opacity: 0.8,
                      fontWeight: 500,
                      letterSpacing: 0.2,
                      color: '#fff'
                    }}
                  >
                    -{formatDuration(duration - played)}
                  </Typography>
                )}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    alignItems: 'center'
                  }}
                  onMouseEnter={() => setHoverVolume(true)}
                  onMouseLeave={() => setHoverVolume(false)}
                >
                  <IconButton
                    style={{ color: '#fff' }}
                    onClick={() => {
                      setVolume(volume === 0 ? 1 : 0);
                    }}
                  >
                    {!volume ? (
                      <VolumeOffIcon fontSize="small" />
                    ) : (
                      <VolumeDownIcon fontSize="small" />
                    )}
                  </IconButton>
                  {hoverVolume && (
                    <Slider
                      sx={{
                        '& input[type="range"]': {
                          WebkitAppearance: 'slider-vertical'
                        },
                        height: '50px',
                        marginBottom: '8px',
                        color: '#fff'
                      }}
                      size={'small'}
                      step={0.1}
                      orientation="vertical"
                      aria-label="Volume"
                      value={volume}
                      max={1}
                      onChange={(_, value) => {
                        setVolume(value as number);
                      }}
                    />
                  )}
                </Box>
                <SettingVideo
                  videoRef={videoRef}
                  handleSetPlaybackRate={handleSetPlaybackRate}
                />
                <IconButton
                  style={{ color: '#fff' }}
                  onClick={() => {
                    setIsFullScreen(!isFullScreen);
                    if (isFullScreen) {
                      document.exitFullscreen();
                    } else {
                      boxVideoRef.current.requestFullscreen();
                    }
                  }}
                >
                  {isFullScreen ? (
                    <CloseFullscreenIcon fontSize="small" />
                  ) : (
                    <OpenInFullIcon fontSize="small" />
                  )}
                </IconButton>
              </Box>
            ) : null}
          </Box>
        </div>
      </div>
    );
  };

  return <>{renderVideo()}</>;
};

export default ReactPlayerVideo;
