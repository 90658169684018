import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
  TextField
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import * as React from 'react';
import UploadMultipleFiles from '../SocialFieldUpdate/UploadMultipleFiles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { uploadMediaSaga } from 'src/store/saga/socialPostSaga';
import {
  reportShop,
  sendReportCategories
} from 'src/apis/socialMarketplace.api';
import ButtonInherit from 'src/components/Button/ButtonInherit';
import { buttonColor } from 'src/constants/styles';
import { useSelector } from 'react-redux';
import { getListReportPageShop } from 'src/apis/socialPages.api';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const CustomRadio = styled(Radio)({
  '&.MuiRadio-root.Mui-checked': {
    color: buttonColor.backgroundColor
  }
});

const useStyles = makeStyles(
  (theme: any) =>
    createStyles({
      wrapContentBox: {
        padding: '0px 30px'
      },
      wrapContentContainer: {
        marginTop: '20px'
      },
      wrapChooseReason: {
        margin: '15px 0 15px 0 '
      }
    }),
  { index: 1 }
);

export default function DialogShopReport(props) {
  const {
    open,
    handleClose,
    title,
    headerText,
    headerSubText,
    pageInfo,
    listReasons,
    setNoti,
    setOpenSnackbar
  } = props;
  const classes = useStyles();

  const [errorMedia, setErrorMedia] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [listReport, setListReport] = React.useState([]);

  const validationSchema = yup.object().shape({
    images: yup
      .array()
      .max(6, `Chỉ có thể tải lên 6 ảnh trong một lần`)
      .min(1, `Bạn phải tải lên ít nhất 1 ảnh`),
    shop_report_category_id: yup.number().min(1, `Lý do không được bỏ trống`)
  });

  const formik = useFormik({
    initialValues: {
      page_id: pageInfo.id,
      shop_report_category_id: 0,
      description: '',
      images: []
    },
    validationSchema,
    onSubmit: values => {
      handleReport(values);
    }
  });
  const fetchListReportPageShop = async () => {
    const response = await getListReportPageShop();
    setListReport(
      response?.data?.map(item => {
        return { ...item, label: item.text };
      })
    );
  };
  React.useEffect(() => {
    fetchListReportPageShop();
  }, []);

  const handleReport = async values => {
    setLoading(true);
    try {
      const imagesPromise = !!values.images?.length
        ? uploadMediaSaga(values.images, 'moment')
        : [];

      const [listIdImages] = await Promise.all([imagesPromise]);

      const data = {
        note: values.description,
        entity_id: pageInfo?.id,
        entity_type: 'Page',
        report_category_id: values?.shop_report_category_id,
        report_images: listIdImages
      };

      const res = await sendReportCategories(data);

      if (res.status === 200) {
        handleClose();
        setNoti({
          code: 200,
          message: 'Gửi yêu cầu báo cáo shop thành công'
        });
      } else {
        setNoti({
          code: 400,
          message: 'Gửi yêu cầu báo cáo shop thất bại'
        });
      }
    } catch (error: any) {
      setNoti({
        code: 400,
        message:
          error?.response?.data?.error || 'Gửi yêu cầu báo cáo shop thất bại'
      });
    } finally {
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  const handleChange = e => {
    formik.setFieldValue('shop_report_category_id', e.target.value);
  };

  const handleChangeDescription = e => {
    formik.setFieldValue('description', e.target.value);
  };

  const renderError = (formik, field: any) => {
    if (
      (field === 'images' && formik.errors.images) ||
      (formik.touched?.[field] && formik.errors[field])
    ) {
      return (
        <Typography fontSize={13} mt="8px" color="red">
          {formik.errors[field]}
        </Typography>
      );
    } else if (field === 'video') {
      return (
        <Typography mt="8px" fontSize={13} color="red">
          {errorMedia}
        </Typography>
      );
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          align="center"
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          {title}
        </DialogTitle>
        <DialogContent dividers>
          <Box className={classes.wrapContentBox}>
            <Typography fontSize={20} fontWeight={600}>
              {headerText}
            </Typography>
            <Typography fontSize={16} fontWeight={400}>
              {headerSubText}
            </Typography>
            <Box className={classes.wrapContentContainer}>
              <Box className={classes.wrapChooseReason}>
                <Typography fontSize={15} fontWeight={500}>
                  Chọn lý do
                </Typography>
              </Box>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={formik.values.shop_report_category_id}
                onChange={e => handleChange(e)}
              >
                {listReport.map(reason => (
                  <FormControlLabel
                    value={reason.id}
                    control={<CustomRadio />}
                    label={<Typography fontSize={15}>{reason.text}</Typography>}
                  />
                ))}
                {renderError(formik, 'shop_report_category_id')}
              </RadioGroup>
              <Box>
                <Box className={classes.wrapChooseReason}>
                  <Typography fontSize={16} fontWeight={500}>
                    Mô tả
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={15}>
                    Chi tiết nội dung tố cáo
                  </Typography>
                  <TextField
                    multiline
                    minRows={4}
                    maxRows={6}
                    fullWidth
                    onChange={e => handleChangeDescription(e)}
                    placeholder="Vui lòng nêu rõ vấn đề bạn gặp phải với lý do đã chọn."
                  />
                </Box>
                <Box sx={{ marginTop: '20px' }}>
                  <Typography fontSize={15}>Hình ảnh minh chứng</Typography>
                  <Box>
                    <UploadMultipleFiles
                      type="file_image"
                      field="images"
                      formik={formik}
                      setErrorMedia={setErrorMedia}
                      action={file => {
                        formik.setFieldValue('images', file);
                      }}
                      validationSchema={validationSchema}
                      maxImage={6}
                    />
                    {renderError(formik, 'images')}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonInherit
            label="Gửi báo cáo"
            disabled={
              !!formik.errors.images || !!formik.errors.shop_report_category_id
            }
            loading={loading}
            style={{
              height: '35px',
              backgroundColor: buttonColor.newBackgroundColor,
              color: 'white'
            }}
            action={() => formik.submitForm()}
          />
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}
